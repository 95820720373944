<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md10 lg8>
        <v-sheet color="white" elevation="20" class="pa-2">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card height="100%" flat>
                <v-card-title>
                  <h3>Comprobante de Egreso a Caja</h3>
                </v-card-title>
                <v-card-text>
                  <b>{{ this.store.PartyName }}</b>
                  <br />
                  {{ this.store.store_address + " , " + this.store.store_city }}<br />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card height="100%" flat>
                <v-toolbar flat>
                  <h3>Pago a:</h3>
                  <v-spacer></v-spacer>
                  <v-btn color="red" fab icon @click="client_dialog = !client_dialog">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  Nombre: {{ this.client.PartyName }} <br />
                  {{ get_name_typeId(this.client.typeid) }} :
                  {{ this.client.PartyIdentification }}<br />
                  Dirección: {{ this.client.CityName }}<br />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-data-table :headers="headers" :items="concepts" :items-per-page="10" item-key="id" sort-by="name"
                class="elevation-1" :loading="loading_status" loader-height="10" loading-text="Cargando ...">
                <template #top>
                  <v-toolbar flat>
                    <v-toolbar-title>Por concepto de</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab icon color="red" @click="concepts_dialog = !concepts_dialog">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }" class="pa-0 ma-0">
                  <v-icon small @click="delete_concept(item)"> mdi-delete </v-icon>
                </template>
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th></th>
                    <th></th>
                    <th class="text-right">
                      {{ sumField(concepts, "item_value").toLocaleString(2) }}
                    </th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>

            </v-col>
            <v-col cols="12" sm="6">
              <v-card height="100%" elevation="1">
                <v-data-table :headers="headers_payments" :items="payments" item-key="id" hide-default-footer
                  sort-by="name" :loading="loading_status" loader-height="10" dense loading-text="Cargando ...">
                  <template #top>
                    <v-toolbar flat>
                      <v-toolbar-title>Formas de pago</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn fab icon color="success" @click="payment_dialogo = !payment_dialogo">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }" class="pa-0 ma-0">
                    <v-icon small @click="delete_payment(item)"> mdi-delete </v-icon>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th>Totales</th>
                      <th class="text-right">
                        {{ sumField(payments, "payment_value").toLocaleString(2) }}
                      </th>
                      <th></th>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card height="100%" flat>
                <v-card-title>
                  Firma
                </v-card-title>
                <v-card-text>
                  <VueSignaturePad class="sign" width="100%" height="120px" ref="signaturePad" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-flex>
    </v-layout>

    <v-dialog v-model="client_dialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon fab @click="client_dialog = !client_dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select v-model="client.typeid" hide-details :items="id_lst" item-value="code" item-text="name"
                label="Tipo de documento" placeholder="Vendedor" outlined />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="client.PartyIdentification" label="Número de documento" ref="idNumber" hide-details
                outlined @keyup="validateID" autofocus clearable />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="client.PartyName" label="Nombre Comopelto" hide-details outlined />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="client.AddressLine" label="Dirección" hide-details outlined />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="client.CityName" label="Ciudad" placeholder="Ciudad" hide-details outlined />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="client_dialog = !client_dialog">Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="concepts_dialog" max-width="600px">
      <v-form ref="conceptForm" v-model="validConcept" @submit="add2concepts">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Por concepto de</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon fab @click="concepts_dialog = !concepts_dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select v-model="concept.item_code" hide-details :items="concept_lst" item-value="codigo"
                  item-text="valor" label="Concepto" placeholder="Concepto" :rules="f_required" outlined />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="concept.item_note" label="Nota / Observación" hide-details :rules="f_required"
                  outlined autofocus />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="concept.item_reference" label="Documento Referencia" :rules="f_required"
                  hide-details outlined />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="concept.item_value" label="Valor" placeholder="Valor" type="number"
                  class="right-input" clearable :rules="f_required" hide-details outlined />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" type="submit">Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="payment_dialogo" max-width="600px">
      <v-form v-model="payvalid" ref="payform" @submit="addPayment">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Registrar Pagos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon fab @click="payment_dialogo = !payment_dialogo">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select v-model="pay_mode" :items="FP" return-object item-value="codigo" hide-details
                  item-text="valor" label="Forma de pago" placeholder="Forma de pago" outlined />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field v-model="payment.payment_reference" label="Documento / Referencia" :error="payRefe"
                  @keyup="updateRefe" required outlined hide-details />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="payment.payment_value" label="Valor" type="number" class="right-input"
                  :error="payValue" clearable :rule="payRule" outlined required hide-details />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!payvalid" text color="success" class="mr-4" type="submit">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-navigation-drawer right class="navigation-drawer" :value="showPrint" width="400px" app>
      <v-card height="100vh">
        <v-toolbar dark>
          <v-toolbar-title>Ingreso</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab icon @click="showPrint = !showPrint">
            <v-icon>
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img height="100%" width="100%" class="white" :src="invoiceImg"></v-img>
        </v-card-text>
      </v-card>
      <v-toolbar class="fixed-footer">
        <v-spacer></v-spacer>
        <v-btn color="success">
          Imprimir
        </v-btn>
      </v-toolbar>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";

import createClient from "../utils/createClient.js";
import createPayment from "../utils/createPayment.js";
import create_item from "../utils/create_item.js";

export default {
  components: {},
  data() {
    return {
      client_dialog: false,
      payment_dialogo: false,
      concepts_dialog: false,
      loading_status: false,
      validConcept: false,
      payvalid: false,
      showPrint: false,
      invoiceImg: null,
      signature: null,
      payment: createPayment(),
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      headers: [
        {
          text: "Concepto",
          align: "start",
          sortable: true,
          value: "item_concept",
          dataType: "text",
        },
        {
          text: "Nota / Observación",
          align: "start",
          sortable: true,
          value: "item_note",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "center",
          sortable: true,
          value: "item_reference",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "item_value",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_payments: [
        {
          text: "Concepto",
          align: "start",
          value: "payment_concept",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      store: createClient(),
      concept: create_item(),
      concepts: [],
      concept_lst: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        codigo: "01",
        valor: "EFECTIVO",
      },
      FP: null,
      client: createClient(),
      signatureStock: null,
      f_required: [(v) => !!v || "! Campo requerido !"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
    };
  },
  mounted() {
    this.store = window.store;
    this.concept_lst = window.settings["GASTOS"];
    this.FP = window.settings.FP;
  },
  methods: {
    onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      console.log("=== End ===");
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    get_name_typeId(e) {
      var index = this.id_lst.findIndex((itm) => itm.code === e);
      if (index >= 0) {
        return this.id_lst[index].name;
      }
    },
    get_concept_text(e) {
      var index = this.concept_lst.findIndex((itm) => itm.codigo === e);
      if (index >= 0) {
        return this.concept_lst[index].valor;
      }
    },
    validateID(e) {
      if (e.keyCode === 13) {
        var qry = {
          store: window.store.store_id,
          PartyIdentification: this.client.PartyIdentification,
        };
        webserver("get_client_info", qry, (data) => {
          console.log(data);
          if (data) {
            this.client = data;
          }
        });
      }
    },
    add2concepts(e) {
      e.preventDefault();
      this.$refs.conceptForm.validate();
      if (this.validConcept) {
        const newItem = { ...this.concept };
        newItem.item_value = parseFloat(newItem.item_value);
        newItem.price = parseFloat(newItem.item_value);
        newItem.item_concept = this.get_concept_text(newItem.item_code);
        this.concepts.push(newItem);
        this.updateTotal();
        this.concept = create_item();
      }
    },
    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      console.log(this.concepts);
      this.concepts.forEach((itm) => {
        this.totals.qty += parseFloat(itm.quantity);
        this.totals.total += parseFloat(itm.quantity) * parseFloat(itm.price);
      });
      this.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;
      console.log(this.payment);
      if (this.totals.unpaid === 0) {
        this.payment_dialogo = false;
        this.topay = true;
        this.save_receipt();
      } else {
        this.topay = false;
      }
    },
    get_client(e) {
      e.preventDefault();
      var qry = {
        store: window.store.store_id,
        PartyIdentification: this.client.PartyIdentification,
      };
      webserver("get_client_info", qry, (data) => {
        console.log(data);
        this.client = data;
        //this.loading_status = false;
        // if (data[0]) {
        //   //this.add2cart(data[0]);
        // }
      });

      console.log(e);
    },
    addPayment(e) {
      e.preventDefault();
      if (this.pay_mode) {
        if (this.pay_mode.codigo === "01") {
          this.payment.payment_concept = this.pay_mode.valor;
          this.payment.payment_code = this.pay_mode.codigo;
          this.payments.push({ ...this.payment });
          this.updateTotal();
          if (this.totals.unpaid < 0) {
            var cambio = {
              payment_concept: "EFECTIVO",
              payment_code: "01",
              payment_value: this.totals.unpaid,
            };
            this.payments.push({ ...cambio });
            this.updateTotal();
          }
          this.pay_mode = {
            codigo: "01",
            valor: "EFECTIVO",
          };
        } else {
          if (this.payment.payment_value <= this.totals.unpaid) {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
            this.pay_mode = {
              codigo: "01",
              valor: "EFECTIVO",
            };
          } else {
            this.payValue = true;
            this.payment.payment_value = 0;
          }
        }
      }
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    save_receipt() {
      var qry = {
        doc: {
          doc_type: "CS-OUT",
          doc_value: this.totals.paid,
          store: window.store.store_id,
          userId: window.profile.user_email,
        },
        items: this.concepts,
        payments: this.payments,
        paidTo: this.client,
        seller: window.seller,
        store: window.store.store_id,
      };
      webserver("receipt_save", qry, (data) => {
        this.resetDoc();
        this.loading_vendors = false;
        console.log(data);
        this.print_doc(data);
      });
    },
    resetDoc() {
      this.payments = [];
      this.concepts = [];
      this.client = createClient();
    },
    delete_payment(e) {
      var index = this.payments.indexOf(e);
      if (index !== -1) {
        this.payments.splice(index, 1);
      }
      this.updateTotal();
    },
    delete_concept(e) {
      var index = this.concepts.indexOf(e);
      if (index !== -1) {
        this.concepts.splice(index, 1);
      }
      this.updateTotal();
    },
    print_doc(data) {
      var LS = 50;
      var itemsQty = data.items.length * LS * 3;
      const elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 1800 + itemsQty;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      const ctx = elem.getContext("2d");

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(window.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(window.seller.PartyName, center, line);
      line += LS;
      ctx.fillText("Nit : " + window.seller.PartyIdentification, center, line);

      line += LS;
      ctx.fillText(
        window.seller.AddressLine + ", " + window.seller.CityName,
        center,
        line
      );
      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText("RECIBO DE CAJA", center, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText("No. " + data.document.doc_number, center, line);
      line += LS;
      ctx.fillText(data.document.issueDate, center, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("C.C./Nit : " + data.buyer.PartyIdentification, left, line);
      line += LS;
      ctx.fillText("Nombre    : " + data.buyer.PartyName, left, line);
      line += LS;
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Concepto", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      var tdiscount = 0;

      data.items.forEach((row) => {
        var price = row.price * row.quantity;
        var discount = row.discount * row.quantity;
        ctx.textAlign = "start";
        ctx.fillText(row.item_concept, left, line);
        line += LS;
        ctx.textAlign = "start";
        ctx.fillText(row.item_note, left, line);
        ctx.textAlign = "end";
        ctx.fillText(price.toLocaleString(), right, line);
        line += LS;
        ctx.textAlign = "start";
        ctx.fillText(row.item_reference, left, line);

        line += LS;
        total = total + price;
        tdiscount = tdiscount + discount;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      line += LS;

      ctx.textAlign = "center";
      ctx.fillText("Formas de pago", center, line);
      line += LS;

      data.payment.forEach((item) => {
        item.valor = parseInt(item.valor);
      });
      console.log(data);
      data.payment.forEach((row) => {
        ctx.textAlign = "start";
        ctx.fillText(
          row.payment_concept + " - " + row.payment_reference,
          left,
          line
        );
        ctx.textAlign = "end";
        ctx.fillText(row.payment_value.toLocaleString(), right, line);
        line += LS;
      });
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
      // var a = window.open("", "", "height=600, width=400");
      // a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      // a.print();
      // a.document.close();
      // setTimeout(() => {
      //   a.close();
      // }, 500);
    },
  },
};
</script>

<style>
.sign {
  border: darkgray solid 1px;
}
</style>
